import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
    Layout, Sidebar,
} from '../../components';
import servicePageStyles from './servicePage.module.scss';

const ServicePage = ({ data }) => {
    const {
        heading, body, metaTitle, metaDescription, subHeading,
    } = data.graphCMS.servicePage;
    const { contentContainer, sideBarDesktop, sideBarMobile } = servicePageStyles;

    return (
        <Layout metaTitle={metaTitle} metaDescription={metaDescription}>
            <div>
                <h1>
                    {heading}
                </h1>
                <h3>
                    {subHeading}
                </h3>
                <div className={contentContainer}>
                    <div>
                        <ReactMarkdown source={body} escapeHtml={false} />
                    </div>
                    <div className={sideBarDesktop}>
                        <Sidebar />
                    </div>
                </div>
                <div className={sideBarMobile}>
                    <Sidebar />
                </div>
            </div>
        </Layout>
    );
};


export const pageQuery = graphql`
  query($id: ID!) {
    graphCMS {
      servicePage(where: { id: $id }) {
        service {
          slug
          name
        }
        id
        heading
        subHeading
        body
        metaTitle
        metaDescription
      }
    }
  }
`;

ServicePage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            servicePage: PropTypes.shape({
                service: PropTypes.shape({
                    slug: PropTypes.string,
                    name: PropTypes.string,
                }),
                id: PropTypes.string,
                heading: PropTypes.string,
                subHeading: PropTypes.string,
                body: PropTypes.string,
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default ServicePage;
